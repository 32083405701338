import {  gql } from '@apollo/client';

export const ADD_ENERGY_POINT_OPERATOR = gql`
  mutation CreateEnergyPointOperator($input: CreateEnergyPointOperatorInput!) {
    createEnergyPointOperator(input: $input) {
      unique_id	
      operatorType
      companyName
      website
      mobile
      email
      companyAddress
    }
  }
`;


export const GET_ENERGY_POINT_OPEARATOR_LIST = gql`
query GetAllEnergyPointOperators($section: String!) {
  getAllEnergyPointOperators(section: $section)
  {
      unique_id
      operatorType
      companyName
      website
      mobile
      email
      companyAddress
  }
}

`;