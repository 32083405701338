import {  gql } from '@apollo/client';

export const CREATE_EVCP_SITE =gql `
mutation CreateEVCPSite($input : CreateEVCPSiteInput!) {
  createEVCPSite(input: $input) {
	unique_id	
    uniqueReadbleCode
    energySite {
        address {
            pin
        }
        geolocation
        {
            latitude
        }
    }
  }
}
`;

export const GET_EVCP_SITES_LIST = gql`
query  GetEVCPSites($section: String!){
    getEVCPSites(section:$section)
  {
	unique_id	
    uniqueReadbleCode   
    epo_id
    epo 
	energySite {
    landsize
	operationStartDate        
	address {
            pin
            state
            district
            subArea
            localArea
            address
    }
	geolocation {
            latitude
            longitude
    }
    }
  }
}

`;

