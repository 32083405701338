import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { CognitoUser,CognitoUserPool } from 'amazon-cognito-identity-js';
import { awsConfig } from './../../aws-config';  // Import AWS and awsConfig

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    confirmationCode: '',
    newPassword: '',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const userPool = new CognitoUserPool(
    {
      UserPoolId: awsConfig.cognito.userPoolId,
      ClientId: awsConfig.cognito.clientId
    });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, confirmationCode, newPassword } = formData;

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        setMessage('Password has been reset successfully. You can now log in with your new password.');
        setError('');
      },
      onFailure: (err) => {
        console.error('Error resetting password:', err);
        setError(`Error: ${err.message}`);
        setMessage('');
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirmation Code"
          name="confirmationCode"
          type="text"
          value={formData.confirmationCode}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="New Password"
          name="newPassword"
          type="password"
          value={formData.newPassword}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Reset Password
        </Button>
      </form>
      {message && (
        <Box mt={2}>
          <Typography variant="body2" color="primary" align="center">
            {message}
          </Typography>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ResetPassword;
