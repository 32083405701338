import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

// Sample data for the report
const data = {
  issues: 10,
  employees: 5,
  devicesFree: 15,
  devicesRunning: 20,
};

// Data for the pie chart
const pieData = [
  { name: 'Free Devices', value: data.devicesFree },
  { name: 'Running Devices', value: data.devicesRunning },
];

// Colors for the pie chart
const COLORS = ['#0088FE', '#00C49F'];

// OperationsReport component
const TodayReport = ({ reportData }) => {
  const { issues, employees, devicesFree, devicesRunning } = reportData;

  return (
    <Box p={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6">Issues Reported</Typography>
            <Typography variant="h4">{issues}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6">Employees Reported</Typography>
            <Typography variant="h4">{employees}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6">Devices Free</Typography>
            <Typography variant="h4">{devicesFree}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6">Devices Running</Typography>
            <Typography variant="h4">{devicesRunning}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Device Status
        </Typography>
        <PieChart width={400} height={400}>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Box>
    </Box>
  );
};

export default TodayReport