import React from 'react';
import { Container, Typography } from '@mui/material';

const DefaultRole = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Welcome to the Prakriti Energy
      </Typography>
      <Typography variant="body1">
        Your user created with Prakriti Energy user role , please reach out your company admin in case of your company role .
      </Typography>
    </Container>
  );
};

export default DefaultRole;
