export const pdiapi_adyayana_in= { 'endpoint':'https://pdiapi.adyayana.in','x-api-key':'zRojQjEdua7H6Qkm7ofeW1bLpmUjJ2JQ963gUSsT',
    resources : 
    {
        'pincodes' : {
            'path':'pincodes/{number}',
            'method':'GET'        
    }
}
}
 

export const google_maps_api= { 'endpoint':'https://maps.googleapis.com','key':'AIzaSyDmbplGcEYfBNMa25Sq58dTN5cpYBJFJDI',
    resources : 
    {
        'geocode' : {
            'path':'/maps/api/geocode/json?address={pincode},India&key={apiKey}',
            'method':'GET'        
    }
}
}