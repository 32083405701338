// src/aws-config.js
import AWS from 'aws-sdk';

const awsConfig = {
  region: 'ap-south-1',
  accessKeyId: 'AKIARGKSM3LLICRADHPP',
  secretAccessKey: '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',
  cognito: {
    userPoolId: 'ap-south-1_VNJjM6zjH',
    clientId: '781vca7in4hdqdjfpcp0kd0td2',
  },
};

// Configure AWS
AWS.config.update({
  region: awsConfig.region,
  accessKeyId: awsConfig.accessKeyId,
  secretAccessKey: awsConfig.secretAccessKey,
});

// Export the config and AWS SDK for use in other parts of the app
export { awsConfig, AWS };
