import React, { useState } from 'react';
import { TextField, Button, Container, Typography ,Link } from '@mui/material';
import { AWS, awsConfig } from './../../aws-config'; 
import { Link as RouterLink,useNavigate } from 'react-router-dom';

const ConfirmSignup = () => {
  const [formData, setFormData] = useState({
    email: '',
    confirmationCode: '',
  });

   const navigate=useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, confirmationCode } = formData;

    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: awsConfig.cognito.clientId,  // Use clientId from awsConfig
      Username: email,
      ConfirmationCode: confirmationCode,
    };

    cognito.confirmSignUp(params, (err, data) => {
      if (err) {
        console.error('Error confirming signup:', err);
        alert(`Error confirming signup: ${err.message}`);  // Display the error message
      } else {
        console.log('Signup confirmation successful:', data);
        alert('Signup confirmation successful!');  // Display success message
        navigate('/login')
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Confirm Signup
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirmation Code"
          name="confirmationCode"
          value={formData.confirmationCode}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Confirm Signup
        </Button>
      </form>
      <Typography variant="body2" align="center" style={{ marginTop: '20px' }}>
        Didn't receive a code?{' '}
        <Link component={RouterLink} to="/request-confirmation-code" color="primary">
          Request a new one
        </Link>
      </Typography>      
    </Container>
  );
};

export default ConfirmSignup;
