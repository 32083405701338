import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Container, Typography } from '@mui/material';

const Profile = () => {
  const { user, userRoles } = useContext(AuthContext);

  if (!user) return <div>Loading...</div>;

  return (
    <Container>
      <Typography variant="h4">Welcome, {user.username}</Typography>
      <Typography variant="body1">Email: {user.email}</Typography>
      <Typography variant="body1">Phone: {user.phone_number}</Typography>
      <Typography variant="body1">Roles: {userRoles.join(', ')}</Typography>
    </Container>
  );
};

export default Profile;
