// src/components/Header.js
import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { AuthContext } from './../../context/AuthContext'; // Import AuthContext

const Header = () => {
  const { isAuthenticated, logout, userRoles } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  return (
    <AppBar position="static" elevation={0} style={{ backgroundColor: 'transparent', paddingTop: '10px' }}>
      <Toolbar>
        <Typography variant="h6" component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'inherit', marginRight: 'auto' }}>
          <img src="/logo.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          Prakriti Energy
        </Typography>
        <nav>
          {isAuthenticated ? (
            <>
              {
                userRoles.length > 0 ? (
                  <>
                    {userRoles[0] === 'pe_company_admin' && <Button component={RouterLink} to="/admin" color="inherit" style={{ color: '#3eaed1' }}>Admin Home</Button>}
                    {userRoles[0] === 'pe_company_engineer' && <Button component={RouterLink} to="/engineer" color="inherit" style={{ color: '#3eaed1' }}>Engineer Home</Button>}
                    {userRoles[0] === 'pe_company_technician' && <Button component={RouterLink} to="/technician" color="inherit" style={{ color: '#3eaed1' }}>Technician Home</Button>}
                    {userRoles[0] === 'pe_user' && <Button component={RouterLink} to="/defaultrole" color="inherit" style={{ color: '#3eaed1' }}>User Home</Button>}
                  </>
                ) : (<><Button component={RouterLink} to="/defaultrole" color="inherit" style={{ color: '#3eaed1' }}>User Home</Button></>)
              }
              <Button color="inherit" component={RouterLink} to="/profile" style={{ color: '#3eaed1' }}>Profile</Button>
              <Button color="inherit" onClick={handleLogout} style={{ color: '#3eaed1' }}>Logout</Button>
            </>
          ) : (
            <>
              <Button component={RouterLink} to="/" color="inherit" style={{ color: '#3eaed1' }}>Home</Button>
              <Button component={RouterLink} to="/about" color="inherit" style={{ color: '#3eaed1' }}>About</Button>
              <Button component={RouterLink} to="/services" color="inherit" style={{ color: '#3eaed1' }}>Services</Button>
              <Button component={RouterLink} to="/login" color="inherit" style={{ color: '#3eaed1' }}>Login</Button>
              <Button component={RouterLink} to="/signup" color="inherit" style={{ color: '#3eaed1' }}>Sign Up</Button>
            </>
          )}

        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;


/*
// src/Header.js
import React from 'react';
import logo from './logo.png';
import './Header.css'; // Importing CSS file for styling
import './../../Common.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-logo">
      <img src={logo} alt="Logo" className="logo-image" />
      </div>
      <nav className="header-nav">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="/login" class="button-link">login</a></li>
          <li><a href="/signup" class="button-link">signup</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
*/