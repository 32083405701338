import React from 'react';
import './Home.css'; // Import your global styles


function Home() {
  return (
    <div className="Home">      
      <main>
        {/* Your main content goes here */}
      </main>            
    </div>
  );
}

export default Home;
