import React, { useState } from 'react';
import { TextField, Button, Container, Typography ,Link ,Box} from '@mui/material';
import { AWS, awsConfig } from './../../aws-config'; 
import { Link as RouterLink,useNavigate } from 'react-router-dom';


const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    password: '',
  });

  const navigate=useNavigate()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, mobileNumber, password } = formData;
    const phoneNumber = `+91${mobileNumber.replace(/\D/g, '')}`;
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: awsConfig.cognito.clientId,
      Username: email,
      Password: password,
      UserAttributes: [
        { Name: 'given_name', Value: firstName },
        { Name: 'family_name', Value: lastName },
        { Name: 'email', Value: email },
        { Name: 'phone_number', Value: phoneNumber },
      ],
    };

    cognito.signUp(params, (err, data) => {
      console.info(params)
      if (err) {
        console.error('Error signing up:', err);
        alert(`Error signing up: ${err.message}`); 
      } else {
        console.log('Signup successful:', data);
        alert('Signup successful! Please check your email for the confirmation code.');  // Display success message
        navigate('/confirm-signup');  // Navigate to confirm-signup page
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mobile Number"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign Up
        </Button>
      </form>
      <Box mt={2}>
        <Typography variant="body2" align="center">
          Already have an account?{' '}
          <Link component={RouterLink} to="/login" color="primary">
            Log in
          </Link>
        </Typography>
      </Box>      
    </Container>
  );
};

export default Signup;
