import React, { createContext, useState,useContext } from 'react';
import {AWS , awsConfig} from '../aws-config';


export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState([]);


  const cognito = new AWS.CognitoIdentityServiceProvider();

  const login = async (username, password) => {
    const params = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: awsConfig.cognito.clientId,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };

    try {
      const response = await cognito.initiateAuth(params).promise();
      const idToken = response.AuthenticationResult.IdToken;
      const accessToken = response.AuthenticationResult.AccessToken;

      const user = {
        username,
        idToken,
        accessToken,
      };

      setUser(user);
      setIsAuthenticated(true);

      // Decode the ID token to get user roles
      const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
      const roles = decodedToken['cognito:groups'] || [];
      setUserRoles(roles);

      // Fetch user details
      await fetchUserDetails(accessToken);

      return user;
    } catch (error) {
      console.error('Error during login', error);
      throw error;
    }
  };

  const fetchUserDetails = async (accessToken) => {
    const params = {
      AccessToken: accessToken
    };

    try {
      const response = await cognito.getUser(params).promise();
      const attributes = response.UserAttributes.reduce((acc, attribute) => {
        acc[attribute.Name] = attribute.Value;
        return acc;
      }, {});

      setUser((prevUser) => ({
        ...prevUser,
        ...attributes
      }));
    } catch (error) {
      console.error('Error fetching user details', error);
    }
  };

  const logout = async () => {
    setUser(null);
    setIsAuthenticated(false);
    setUserRoles([]);    
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, userRoles, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
