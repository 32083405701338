import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { awsConfig } from './../../aws-config';  // Import AWS and awsConfig
import {useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const navigate=useNavigate()

  const userPool = new CognitoUserPool(
    {
      UserPoolId: awsConfig.cognito.userPoolId,
      ClientId: awsConfig.cognito.clientId
    });

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log('Password reset initiated:', data);
        setMessage('Password reset initiated. Please check your email for the confirmation code.');
        setError('');
        alert('Password reset initiated! Please check your email for the confirmation code.');  // Display success message
        navigate('/resetpassword');  // Navigate to confirm-signup page
      },
      onFailure: (err) => {
        console.error('Error initiating password reset:', err);
        setError(`Error: ${err.message}`);
        setMessage('');
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
      {message && (
        <Box mt={2}>
          <Typography variant="body2" color="primary" align="center">
            {message}
          </Typography>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ForgotPassword;
