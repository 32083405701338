// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './Home';
import LoginPage from './pages/login/Login';
import SignupPage from './pages/login/Signup'
import ForgotPasswordPage from './pages/login/ForgotPassword'
import ResetPasswordPage from './pages/login/ResetPassword'
import ConfirmSignupPage from './pages/login/ConfirmSignup'
import RequestConfirmationCodePage from './pages/login/RequestConfirmationCode';
import DefaultRolePage from './pages/role/DefaultRole'
import AdminPage from './pages/role/admin/Admin'
import EngineerPage from './pages/role/user/Engineer'
import NotFoundPage from './pages/NotFound';
import ProfilePage from './pages/login/Profile';
import TechnicianPage from './pages/role/user/Technician'
import EVCPSitePage  from './pages/role/admin/EVCPS/EVCPS';
import NewEVCPSitePage  from './pages/role/admin/EVCPS/NewEVCPS';
import EnergyPointOperator  from './pages/role/admin/EnergyPointOperator';
import { AuthProvider } from './context/AuthContext';



const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <div>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/confirm-signup" Component={ConfirmSignupPage} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/resetpassword" element={<ResetPasswordPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/request-confirmation-code" element={<RequestConfirmationCodePage />} />
              <Route path="/admin/*" element={<AdminPage />} />
              <Route path="/admin/evcp-site" element={<EVCPSitePage />} />
              <Route path="/admin/evcp-site/new" element={<NewEVCPSitePage />} />              
              <Route path="/admin/energy-point-operator" element={<EnergyPointOperator />} />              
              <Route path="/engineer" element={<EngineerPage />} />
              <Route path="/technician" element={<TechnicianPage />} />
              <Route path="/defaultrole" element={<DefaultRolePage />} />
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Layout>
      </Router>
    </AuthProvider>
  );
};

export default App;
