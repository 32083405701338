import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TodayReport from '../../../components/admin/TodayReport'

function Admin() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const reportData = {
    issues: 10,
    employees: 5,
    devicesFree: 15,
    devicesRunning: 20,
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={2}>
      <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/admin/energy-point-operator')}>
            Energy point operators 
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/admin/evcp-site')}>
            EV charging point sites 
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/inventory')}>
            Inventory
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/vendors')}>
            Vendors
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/stats')}>
            Stats
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleNavigation('/employees')}>
            Employee
          </Button>
        </Grid>
      </Grid>
      <Box mt={4}>
      <Typography variant="h5" gutterBottom>
        Today's operations live report view :
      </Typography>
      </Box>  
    <div>
      <TodayReport reportData={reportData} />
    </div>
    </Container>
  );
}

export default Admin;
