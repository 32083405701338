import React from 'react';
import { Link, Typography } from '@mui/material';

const GeoLocationLink = ({ latitude, longitude }) => {
    const handleClick = (event) => {
      // Open the Google Maps link in a new tab
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsUrl, '_blank');
      event.preventDefault(); // Prevent default link behavior
    };
  
    return (
      <Link
        href="#"
        onClick={handleClick}
        style={{ color: 'blue', textDecoration: 'underline' }}
      >
        <Typography variant="body1">
          View Location on Maps
        </Typography>
      </Link>
    );
  };
  

export default GeoLocationLink;
