import React from 'react';
import { Container, Typography } from '@mui/material';

const Engineer = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
      Engineer
      </Typography>
      <Typography variant="body1">
        This is a protected Engineer.
      </Typography>
    </Container>
  );
};

export default Engineer;
