import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { AWS, awsConfig } from './../../aws-config';  // Import AWS and awsConfig
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation


const RequestConfirmationCode = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();  // Use navigate for navigation
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: awsConfig.cognito.clientId,  // Use clientId from awsConfig
      Username: email,
    };

    cognito.resendConfirmationCode(params, (err, data) => {
      if (err) {
        console.error('Error requesting confirmation code:', err);
        setMessage(`Error: ${err.message}`);  // Display the error message
      } else {
        console.log('Confirmation code requested:', data);
        alert('Confirmation code has been sent! Please check your email.');  // Display success message
        navigate('/confirm-signup', { state: { email } }); 
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Request Confirmation Code
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Request Code
        </Button>
      </form>
      {message && (
        <Typography variant="body2" color="textSecondary" align="center">
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default RequestConfirmationCode;
