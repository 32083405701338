import React, { useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, Button } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GET_EVCP_SITES_LIST } from '../../../../graphql/EVCPGPL'
import { Link as RouterLink } from 'react-router-dom';
import { SECTION_EVCPSites } from '../../../../globalSection'
import  GeoLocationLink  from '../../../../components/GeoLocationLink'



const EVCPS = () => {
  const [getEVCPSites, { loading: query_evcp_sites_list_Loading, error: query_evcp_sites_list_Error, data: query_evcp_sites_list_Data }] = useLazyQuery(GET_EVCP_SITES_LIST);
  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      getEVCPSites({ variables: { section: SECTION_EVCPSites } });
      effectRan.current = true
    }
  }, [getEVCPSites]);

  console.log(query_evcp_sites_list_Data)


  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Admin/EV charging point sites   <Button component={RouterLink} to="/admin/evcp-site/new" color="inherit" style={{ color: '#3eaed1' }}>add new EV charging point site </Button>
      </Typography>
      {query_evcp_sites_list_Loading && <Typography>Loading...</Typography>}
      {query_evcp_sites_list_Error && <Typography color="error">{query_evcp_sites_list_Error.message}</Typography>}
      {query_evcp_sites_list_Data && (
        <TableContainer component={Paper}>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>Energy point operator</TableCell>
                <TableCell style={{ width: '20%' }}>address</TableCell>
                <TableCell style={{ width: '20%' }}>geolocation</TableCell>
                <TableCell style={{ width: '20%' }}>landSize</TableCell>
                <TableCell style={{ width: '20%' }}>operational startDate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {query_evcp_sites_list_Data.getEVCPSites.map(item => (
                <TableRow key={item.unique_id}>
                  <TableCell>{item.epo}</TableCell>
                  <TableCell>{
                    item.energySite.address.address + ','
                    + item.energySite.address.localArea + ','
                    + item.energySite.address.subArea + ','
                    + item.energySite.address.district + ','
                    + item.energySite.address.state + ','
                    + item.energySite.address.pin + '.'
                  }
                  </TableCell>
                  <TableCell><GeoLocationLink latitude={item.energySite.geolocation.latitude} longitude={item.energySite.geolocation.longitude} /></TableCell>
                  <TableCell>{item.energySite.landsize}</TableCell>
                  <TableCell>{item.energySite.operationStartDate}</TableCell>
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default EVCPS;
