import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TextField, Button, Container, Box, Typography, Select, MenuItem, FormControl, InputLabel, FormHelperText, Paper, Table, TableCell, TableRow, TableContainer, TableHead, TableBody } from '@mui/material';
import { GET_ENERGY_POINT_OPEARATOR_LIST, ADD_ENERGY_POINT_OPERATOR } from '../../../graphql/EnergyPointOperatorGPL'
import {SECTION_ADD_ENERGY_POINT_OPERATOR} from '../../../globalSection'

const EnergyPointOperator = () => {
  // State for form data and errors
  const [formData, setFormData] = useState({
    operatorType: '',
    companyName: '',
    website: '',
    mobile: '',
    email: '',
    companyAddress: ''
  });
  const [errors, setErrors] = useState({});

  // Hooks for GraphQL queries and mutations
  const [getEnergyPointOperators, {loading: queryLoading, error: queryError, data: queryData }] = useLazyQuery(GET_ENERGY_POINT_OPEARATOR_LIST);
  const [addEnergyPointOperator, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(ADD_ENERGY_POINT_OPERATOR);

    // Handle form input changes
  const handleChange = (e) => {    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value     

    });
    if (e.target.name==='operatorType')
    {
      getEnergyPointOperators({ variables: { section: SECTION_ADD_ENERGY_POINT_OPERATOR[e.target.value]['code'] } });
      console.info('queryData:' + JSON.stringify(queryData))
    }
    

  };

  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.operatorType) newErrors.operatorType = 'Operator Type is required';
    if (!formData.companyName) newErrors.companyName = 'Company Name is required';
    if (!formData.website) newErrors.website = 'Website is required';
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(formData.website)) newErrors.website = 'Enter a valid URL';
    if (!formData.mobile) newErrors.mobile = 'mobile Number is required';
    if (!/^[0-9]{10}$/.test(formData.mobile)) newErrors.mobile = 'Enter a valid 10-digit mobile number';
    if (!formData.email) newErrors.email = 'Email Address is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Enter a valid email address';
    if (!formData.companyAddress) newErrors.companyAddress = 'Company Address is required';
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      formData['functional_section'] = SECTION_ADD_ENERGY_POINT_OPERATOR[formData.operatorType]['code']
      try {
        await addEnergyPointOperator({ variables: { input: formData } });
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render component
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Energy point operator
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mt: 2 }} error={!!errors.operatorType}>
            <InputLabel id="operator-type-label">Point operator type</InputLabel>
            <Select
              labelId="operator-type-label"
              id="operator-type"
              name="operatorType"
              value={formData.operatorType}
              onChange={handleChange}
            >
              {Object.entries(SECTION_ADD_ENERGY_POINT_OPERATOR).map(([key, value]) => (
                <MenuItem value={key}>{value['description']}</MenuItem>
              ))}
            </Select>
            {errors.operatorType && <FormHelperText>{errors.operatorType}</FormHelperText>}
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            id="company-name"
            label="Company Name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="website"
            label="Website"
            name="website"
            value={formData.website}
            onChange={handleChange}
            error={!!errors.website}
            helperText={errors.website}
          />
          <TextField
            margin="normal"
            fullWidth
            id="mobile"
            label="mobile Number"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            error={!!errors.mobile}
            helperText={errors.mobile}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            id="company-address"
            label="Company Address"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            error={!!errors.companyAddress}
            helperText={errors.companyAddress}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          {mutationLoading && <Typography>Loading...</Typography>}
          {mutationError && <Typography color="error">{mutationError.message}</Typography>}
          {mutationData && <Typography>Energy point operator added successfully!</Typography>}
        </Box>
      </Box>
      {queryLoading && <Typography>Loading...</Typography>}
      {queryError && <Typography color="error">{queryError.message}</Typography>}
      {queryData && (
        <TableContainer component={Paper}>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>Energy point operator</TableCell>
                <TableCell style={{ width: '20%' }}>Website</TableCell>
                <TableCell style={{ width: '20%' }}>Mobile</TableCell>
                <TableCell style={{ width: '20%' }}>Email</TableCell>
                <TableCell style={{ width: '20%' }}>companyAddress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryData.getAllEnergyPointOperators.map(item => (
                <TableRow key={item.unique_id}>
                  <TableCell>{SECTION_ADD_ENERGY_POINT_OPERATOR[item.operatorType]['description']}</TableCell>
                  <TableCell>{item.companyName}</TableCell>
                  <TableCell>{item.website}</TableCell>
                  <TableCell>{item.mobile}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.companyAddress}</TableCell>
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default EnergyPointOperator;
