// src/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';

const httpLink = new HttpLink({
  uri: 'https://graphql.prakriti.energy/graphql',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization to the headers
  operation.setContext({
    headers: {
      'x-api-key': 'da2-btix3drf25byhg44dmgudym33m',
    }
  });

  return forward(operation);
});

const graphqlClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default graphqlClient;
