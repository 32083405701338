import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid, Container, Typography, FormHelperText } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GET_ENERGY_POINT_OPEARATOR_LIST } from '../../../../graphql/EnergyPointOperatorGPL'
import { CREATE_EVCP_SITE } from '../../../../graphql/EVCPGPL'
import { SECTION_EVCPSites, SECTION_ADD_ENERGY_POINT_OPERATOR } from '../../../../globalSection'
import { pdiapi_adyayana_in, google_maps_api } from '../../../../dataapiConfig'


function NewEVCPSite() {
  const [selectedSiteOperatorID, setsiteOperatorID] = useState('');
  const [selectedSiteOperator, setsiteOperator] = useState('');
  const [pincode, setPincode] = useState('');
  const [stateName, setStateName] = useState('');
  const [districtName, setDistrictName] = useState('');
  const [talukName, setTalukName] = useState('');
  const [localAreas, setLocalAreas] = useState([]);
  const [selectedLocalCode, setSelectedLocalCode] = useState('');
  const [landMark, setLandMark] = useState('');
  const [address, setAddress] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: '', lng: '' });
  const [landSize, setLandSize] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [pincodeError, setPincodeError] = useState(null);
  const [submitErrors, setSubmitErrors] = useState({});
  const [geoError, setGeoError] = useState('');
  const [dateError, setDateError] = useState('');
  const effectRan = useRef(false);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };


  const [getEnergyPointOperators, { loading: queryLoading, error: queryError, data: queryData }] = useLazyQuery(GET_ENERGY_POINT_OPEARATOR_LIST);
  const [addEVCPSite, { data: addEVCPSiteData, loading: addEVCPSiteLoading, error: addEVCPSiteError }] = useMutation(CREATE_EVCP_SITE);


  const validate = () => {
    const newErrors = {};
    if (!selectedDate) {
      setDateError('Date selection is required')
      newErrors.selectedDate = 'Date selection is required'
    }    
    if (!selectedSiteOperatorID) newErrors.selectedSiteOperatorID = 'Select the operator.';
    if (!stateName) newErrors.stateName = 'State name is required';
    if (!districtName) newErrors.districtName = 'District name is required';
    if (!talukName) newErrors.talukName = 'Taluk name is required';
    if (!pincode) newErrors.pincode = '6 digits pincode number is required';
    if (!/^[0-9]{6}$/.test(pincode)) newErrors.pincode = 'Enter a valid 6-digit pincode number';
    if (!landMark) newErrors.landMark = 'Site land mark is required';
    if (!address) newErrors.address = 'site address is required';
    if (!/^\d*\.?\d+$/.test(landSize)) newErrors.landSize = 'Enter a valid number'
    if (selectedLocalCode==='') newErrors.selectedLocalCode = 'Select the operator.';
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents default form submission
    const validationErrors = validate();
    setSubmitErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // Data to be sent to the server
      const formData = {
        "functional_section": SECTION_EVCPSites,
        "epo_id": selectedSiteOperatorID,
        "epo": selectedSiteOperator,
        "energySite": {
          "address": {
            "pin": pincode,
            "state": stateName,
            "district": districtName,
            "subArea": talukName,
            "localArea": localAreas[selectedLocalCode],
            "address": address
          },
          "geolocation": {
            "latitude": coordinates.lat,
            "longitude": coordinates.lng
          },
          "landsize": landSize,
          "operationStartDate": selectedDate
        },
        "uniqueReadbleCode": uniqueCode
      };
      formData['functional_section'] = SECTION_EVCPSites
      alert(JSON.stringify(formData))
      try {
        await addEVCPSite({ variables: { input: formData } });
      } catch (err) {
        setSubmitErrors(err.message);
        console.error(err);
      }
    }
  };


  const handlePincodeChange = async (event) => {
    const pincodeValue = event.target.value;
    setPincode(pincodeValue);

    if (pincodeValue.length === 6) { // Assuming pincode is 6 digits
      setPincodeLoading(true);
      try {
        const response = await fetch(pdiapi_adyayana_in['endpoint'] + '/pincodes/' + pincodeValue, {
          method: 'GET',
          headers: {
            'x-api-key': pdiapi_adyayana_in['x-api-key'],
          }
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const resp_data = await response.json();
        console.info(resp_data.data.local_area)
        setStateName(resp_data.data.statename || '');
        setDistrictName(resp_data.data.districtname || '');
        setTalukName(resp_data.data.taluk || '');
        setLocalAreas(resp_data.data.local_area || []);
        //setSelectedLocalCode(resp_data.data.local_area.length > 0 ? 0 : ''); // Set default local code if available                        
        setUniqueCode('IN_' + resp_data.data.statename?.substring(0, 2) + '_' + resp_data.data.districtname?.substring(0, 2) + '_' + resp_data.data.taluk?.substring(0, 2) + '_' + pincodeValue)
        fetchCoordinates(pincodeValue)
      } catch (error) {
        setPincodeError(error.message);
      } finally {
        setPincodeLoading(false);
      }
    }
  };

  const handleLocalCodeChange = (event) => {
    setSelectedLocalCode(event.target.value);
    setUniqueCode('IN_' + stateName?.substring(0, 2) + '_' + districtName?.substring(0, 2) + '_' + talukName?.substring(0, 2) + '_' + localAreas[event.target.value]?.substring(0, 2) + '_' + landMark?.substring(0, 2) + '_' + pincode)
  };

  const handleSiteOperatorChange = (event) => {    
    setsiteOperatorID(event.target.value);
    setsiteOperator(queryData.getAllEnergyPointOperators.find(operator => operator.unique_id === event.target.value)?.companyName)
  };



  useEffect(() => {
    if (!effectRan.current) {
      getEnergyPointOperators({ variables: { section: SECTION_ADD_ENERGY_POINT_OPERATOR['evcpo']['code'] } });
      effectRan.current = true
    }
  }, [getEnergyPointOperators]);

  const handleChange = (e) => {
    if (e.target.name === 'txt_land_mark') {
      setLandMark(e.target.value)
    }
    if (e.target.name === 'txt_address') {
      setAddress(e.target.value)
    }
    if (e.target.name === 'txt_landSize') {
      setLandSize(e.target.value)
    }
    setUniqueCode('IN_' + stateName?.substring(0, 2) + '_' + districtName?.substring(0, 2) + '_' + talukName?.substring(0, 2) + '_' + localAreas[selectedLocalCode]?.substring(0, 2) + '_' + landMark?.substring(0, 2) + '_' + pincode)
  }

  const fetchCoordinates = async (pincodeValue) => {
    const url = google_maps_api.endpoint + `/maps/api/geocode/json?address=${pincodeValue},India&key=${google_maps_api.key}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({ lat, lng });
        setGeoError('');
      } else {
        setGeoError('Could not find coordinates for the given PIN code.');
        setCoordinates({ lat: '', lng: '' });
      }
    } catch (err) {
      setGeoError('An error occurred while fetching the coordinates.');
      setCoordinates({ lat: '', lng: '' });
    }
  };


  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Admin/Add new EV charging point site
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ mt: 2 }} error={!!submitErrors.selectedSiteOperatorID}>
              <InputLabel>EV charging point  operator</InputLabel>
              <Select
                name="dd_ev_charging_point_operator"
                value={selectedSiteOperatorID}
                onChange={handleSiteOperatorChange}
              >
                {queryData?.getAllEnergyPointOperators.map((operator) => (
                  <MenuItem key={operator.unique_id} value={operator.unique_id}>
                    {operator.companyName}
                  </MenuItem>
                ))}

              </Select> {queryLoading && <Typography>Loading...</Typography>}
              {queryError && <Typography color="error">{queryError.message}</Typography>}
              {submitErrors.selectedSiteOperatorID && <FormHelperText>{submitErrors.selectedSiteOperatorID}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="txt_pin"
              label="Location PIN code"
              variant="outlined"
              fullWidth
              value={pincode}
              onChange={handlePincodeChange}
              error={!!submitErrors.pincode}
              helperText={submitErrors.pincode}
            />{pincodeLoading && <Typography>Loading...</Typography>}
            {pincodeError && <Typography color="error">{pincodeError.message}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_state"
              label="State"
              variant="outlined"
              fullWidth
              value={stateName}
              error={!!submitErrors.stateName}
              helperText={submitErrors.stateName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_district"
              label="District/City"
              variant="outlined"
              fullWidth
              value={districtName}
              error={!!submitErrors.districtName}
              helperText={submitErrors.districtName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_taluk"
              label="Taluk/Town"
              variant="outlined"
              fullWidth
              value={talukName}
              error={!!submitErrors.talukName}
              helperText={submitErrors.talukName}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ mt: 2 }} error={!!submitErrors.selectedLocalCode}>
              <InputLabel>Local Area</InputLabel>
              <Select
                name="dd_local_area"
                value={selectedLocalCode}
                onChange={handleLocalCodeChange}
              >
                {localAreas.map((option, index) => (
                  <MenuItem key={index} value={index}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {submitErrors.selectedLocalCode && <Typography color="error">{submitErrors.selectedLocalCode}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_land_mark"
              label="Land mark"
              variant="outlined"
              fullWidth
              value={landMark}
              onChange={handleChange}
              error={!!submitErrors.landMark}
              helperText={submitErrors.landMark}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_address"
              label="Adress"
              variant="outlined"
              fullWidth
              value={address}
              onChange={handleChange}
              error={!!submitErrors.address}
              helperText={submitErrors.address}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_unique_code"
              label="Unique Code"
              variant="outlined"
              fullWidth
              value={uniqueCode}
              onChange={handleChange}
              error={!!submitErrors.uniqueCode}
              helperText={submitErrors.uniqueCode}
            />
          </Grid>
          <Grid item xs={12}>
            {geoError && <Typography color="error">{geoError}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Latitude"
              value={coordinates.lat}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Longitude"
              value={coordinates.lng}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="txt_landSize"
              label="Land size in sft"
              variant="outlined"
              fullWidth
              value={landSize}
              onChange={handleChange}
              error={!!submitErrors.landSize}
              helperText={submitErrors.landSize}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const day = String(date.getDate()).padStart(2, '0');
                setSelectedDate(`${year}-${month}-${day}`);
                if (date) {
                  setDateError(''); // Clear error when a date is selected
                }
              }}
              dateFormat="yyyy-MM-dd"
              showTimeSelect={false}
              placeholderText="Select operation start date"
              isClearable
            />{dateError && (
              <Typography variant="caption" color="error">
                {dateError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
            {addEVCPSiteLoading && <Typography>Uploading...</Typography>}
            {addEVCPSiteError && <Typography color="error">{addEVCPSiteError.message}</Typography>}
            {addEVCPSiteData && <Typography>EV charging point site  added successfully!                    
              <Button variant="contained" color="primary" onClick={() => handleNavigation('/admin/evcp-site')}>
                EV charging point sites 
          </Button> 
          </Typography>}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default NewEVCPSite;
