// src/components/Layout.js
import React from 'react';
import Header from '../pages/header/Header';
import Footer from '../pages/footer/Footer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ py: 4 }}>
          {children}
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
